"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WIDGET_CUSTOM_PROPS = exports.WIDGET_ELEMENTS = exports.WIDGET_ELEMENTS_MODAL = exports.WIDGET_ELEMENTS_MODAL_TEXT_CUSTOMIZABLE = exports.WIDGET_ELEMENTS_MODAL_IMG = exports.WIDGET_ELEMENTS_MODAL_LAYOUT = exports.WIDGET_ELEMENTS_TEXT_CUSTOMIZABLE = exports.WIDGET_ELEMENTS_CTA = exports.WIDGET_ELEMENTS_TEXT = exports.WIDGET_ELEMENTS_ICON = exports.WIDGET_ELEMENTS_COMPONENTS = exports.WIDGET_ELEMENTS_IMG = exports.WIDGET_ELEMENTS_LAYOUT = exports.SCREEN_SIZES = exports.MAGIC_STRINGS = exports.MAGIC_STRING_OFFSETS = exports.MAGIC_STRING_GENERAL = exports.CART_REFRESH_OVERRIDES = exports.INFO_ICONS = exports.CTA_TYPES = exports.PLACEMENTS = exports.TEMPLATE_TYPES = exports.TEMPLATES = exports.PAGE_TYPES = void 0;
/* --------------------------------- consts --------------------------------- */
exports.PAGE_TYPES = [
    'product',
    'in_store',
    'cart',
    'checkout_contact_information',
    'checkout_shipping_method',
    'checkout_payment_method',
    'checkout_complete',
    'checkout_unknown'
];
exports.TEMPLATES = [
    'three',
    'four',
    'seven',
    'impact_counter',
    'education_touchpoint',
    'checkout_widget',
    'order_protection_checkout',
    'promo',
    'enhanced_pdp',
    'eco_incentives',
    'impact_receipt',
    'ab_test_widget_a_visual',
    'ab_test_widget_b_cobranded',
    'ab_test_widget_c_action',
    'ab_test_pdp_a_background',
    'ab_test_pdp_b_cobranded',
    'ab_test_pdp_c_action'
];
exports.TEMPLATE_TYPES = [
    'impact',
    'checkout',
    'order_protection',
    'enhanced_pdp',
    'promo',
    'education',
    'incentives',
    'network',
    'receipt'
];
exports.PLACEMENTS = ['before', 'after', 'prepend', 'append'];
exports.CTA_TYPES = ['checkbox', 'toggle', 'button'];
exports.INFO_ICONS = ['info', 'info_outline'];
exports.CART_REFRESH_OVERRIDES = ['default', 'none', 'soft', 'full_page', 'url_redirect'];
exports.MAGIC_STRING_GENERAL = [
    '[brand]',
    '[project]',
    '[project_location]',
    '[total_co2_offset]',
    '[eco_incentives_threshold]',
    '[eco_incentives_trees]',
    '[global_all_time_carbon_offset]',
    '[global_daily_ecocart_order_count]',
    '[ecocart_logo]',
    '[ecocart_logo_black]',
    '[ecocart_logo_white]',
    '[ecocart_logo_white_text_green_plane]',
    '[ecocart_logo_black_text_green_plane]',
    '[info_icon]',
    '[br]',
    '[price]',
    '[amount_needed_for_incentives]',
    '[co2_per_item]',
    '[miles_per_item]',
    '[seedlings_per_item]'
];
// ~ below list should stay 1-1 synced w OFFSET_TYPES, since
// ~ "'const' assertions can only be applied to references to enum members, or string, number, boolean, array, or object literals"
// ~ and so we cannot impute values from OFFSET_TYPES list.
// ~ e.g.
// ~ export const MAGIC_STRING_OFFSETS = [..., '[newOffset]']
// ~ -- make sure to also add --
// ~ export const OFFSET_TYPES         = [..., 'newOffset']
exports.MAGIC_STRING_OFFSETS = [
    '[co2]',
    '[lbs_co2_absorbed_by_one_tree_annually]',
    '[trees]',
    '[miles]',
    '[businessCards]',
    '[arcticIce]',
    '[lightbulbs]'
];
exports.MAGIC_STRINGS = [...exports.MAGIC_STRING_GENERAL, ...exports.MAGIC_STRING_OFFSETS];
exports.SCREEN_SIZES = ['xs', 'sm', 'md', 'lg', 'xl', '2xl'];
/* ----------------------------- widget elements ---------------------------- */
// !DEPRECATABLE: 'modal' will be deprecated once we migrate all merchants to new checkout widget
exports.WIDGET_ELEMENTS_LAYOUT = ['wrapper', 'container', 'content', 'modal'];
exports.WIDGET_ELEMENTS_IMG = ['img', 'logo', 'logo2'];
exports.WIDGET_ELEMENTS_COMPONENTS = ['progress_bar', 'social_proof', 'powered_by'];
exports.WIDGET_ELEMENTS_ICON = ['infoIcon'];
exports.WIDGET_ELEMENTS_TEXT = [
    'header',
    'subHeader',
    'footer',
    'tooltip',
    'amount',
    'header_complete',
    'subheader_in_progress',
    'subheader_complete'
];
// !DEPRECATABLE: 'modalCta' will be deprecated once we migrate all merchants to new checkout widget
exports.WIDGET_ELEMENTS_CTA = ['cta', 'cta2', 'modalCta', 'learnMoreLink', 'upsell'];
exports.WIDGET_ELEMENTS_TEXT_CUSTOMIZABLE = [...exports.WIDGET_ELEMENTS_TEXT, ...exports.WIDGET_ELEMENTS_CTA];
/** Learn More modal elements in Checkout Widget */
exports.WIDGET_ELEMENTS_MODAL_LAYOUT = ['modal_wrapper'];
exports.WIDGET_ELEMENTS_MODAL_IMG = ['modal_hero_img'];
exports.WIDGET_ELEMENTS_MODAL_TEXT_CUSTOMIZABLE = ['modal_header', 'modal_subheader', 'modal_hero_cta', 'modal_cta'];
exports.WIDGET_ELEMENTS_MODAL = [
    ...exports.WIDGET_ELEMENTS_MODAL_LAYOUT,
    ...exports.WIDGET_ELEMENTS_MODAL_IMG,
    ...exports.WIDGET_ELEMENTS_MODAL_TEXT_CUSTOMIZABLE
];
exports.WIDGET_ELEMENTS = [
    ...exports.WIDGET_ELEMENTS_LAYOUT,
    ...exports.WIDGET_ELEMENTS_IMG,
    ...exports.WIDGET_ELEMENTS_COMPONENTS,
    ...exports.WIDGET_ELEMENTS_ICON,
    ...exports.WIDGET_ELEMENTS_TEXT_CUSTOMIZABLE,
    ...exports.WIDGET_ELEMENTS_MODAL
];
exports.WIDGET_CUSTOM_PROPS = [
    'type',
    'url',
    'color',
    'size',
    'learnMoreUrl',
    'activeFontColor',
    'activeBorderColor',
    'activeBorderWidth',
    'activeBorderRadius',
    'activeBackgroundColor',
    'inactiveFontColor',
    'inactiveBorderWidth',
    'inactiveBorderColor',
    'inactiveBorderRadius',
    'inactiveBackgroundColor'
];
