"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MerchantAdmin = exports.AddressInfo = exports.Merchant = exports.MerchantProject = exports.Stats = exports.StatsData = void 0;
const db_entity_metadata_1 = require("./prototypes/db-entity-metadata");
class StatsData {
    constructor() {
        this.ecocart_orders = 0;
        this.carbon_offset = 0;
    }
}
exports.StatsData = StatsData;
class Stats {
    constructor() {
        this.global = { daily: new StatsData() };
        this.merchant = { all_time: new StatsData() };
    }
}
exports.Stats = Stats;
class MerchantProject {
    constructor() {
        this.id = '';
        this.name = '';
        this.images = [''];
        this.url = '';
        this.location = '';
        this.goals = [];
        this.description = '';
        this.verifications = [];
    }
}
exports.MerchantProject = MerchantProject;
class Merchant extends db_entity_metadata_1.DBEntityMetadata {
    constructor() {
        super();
        /* ------------------- Derived from MerchantAdmin (start) ------------------- */
        this.systemVersion = 'v2';
        this.shopName = '';
        this.companyName = '';
        this.platform = 'Shopify';
        this.payorMode = 'customer_paying';
        this.optOut = false; // turn off pre-check https://app.clickup.com/t/45007832/ECO-4364
        this.locale = 'en';
        this.currencyCode = 'USD';
        this.unitSystem = 'imperial';
        this.ecocartEnabled = false;
        this.project = new MerchantProject();
        this.variantIds = [''];
        this.ecoCalculationApiKey = '';
        this.offsetMultiplier = 2;
        this.ecoIncentivesCartValueThreshold = 50;
        this.ecoIncentivesNumberOfTrees = 5;
        this.ecoIncentivesEnabled = false;
        this.experiment = null;
        this.enableCartTagging = true;
        this.pkgProtectionEnabled = false;
        this.pkgProtectionOrderCapEnabled = false;
        this.pkgProtectionOrderCapAmount = 1000;
        this.cashbackEnabled = false;
        this.cashbackPercentage = 0;
        /* -------------------- Derived from MerchantAdmin (end) -------------------- */
        this.totalCO2Offset = 0;
        this.enableAnalytics = true;
        this.widgetMap = {};
        this.widgetPlacements = [];
        this.widgetAllSettings = {};
        this.stats = new Stats();
        this.hidePriceInCheckoutExtension = false;
    }
}
exports.Merchant = Merchant;
class AddressInfo {
    constructor() {
        this.lat = 0;
        this.long = 0;
        this.address1 = '';
        this.address2 = '';
        this.city = '';
        this.province = '';
        this.zip = '';
        this.country = '';
    }
}
exports.AddressInfo = AddressInfo;
class MerchantAdmin extends db_entity_metadata_1.DBEntityMetadata {
    constructor() {
        super();
        /* --------------------------------- private -------------------------------- */
        this.ecocartActive = false; // ~ order logging / billing / everything enabled/disabled
        this.accessToken = ''; // TODO: update to "shopifyAccessToken"?
        this.shopifyAccessTokenIsValid = true;
        this.shopifyStorefrontAccessToken = '';
        this.rechargeAccessToken = '';
        this.rechargeProductId = '';
        this.defaultItemWeightKg = 1;
        this.billingAddress = new AddressInfo();
        this.fulfillmentAddress = new AddressInfo();
        this.manufacturingAddress = new AddressInfo();
        this.pricingMode = 'calculation';
        this.flatRateMode = 'cart_basis';
        this.flatRatePercentage = 0.05;
        this.flatRateLineItemAmount = 0.05;
        this.flatRateCartAmount = 1.04;
        this.fee = 0.15;
        this.offsetPremium = 1.2;
        this.sku = '';
        this.shopifyCategories = [''];
        this.autofulfillmentMode = 'enabled';
        this.enableAutofulfillment = true; // ! deprecatable // re: shopify orders
        this.enableDelayedAutofulfillment = false; // ! deprecatable
        this.enableManufacturing = true; // re: carbon calculation
        this.enableShipping = true; // re: carbon calculation
        this.enableEcoRewards = false;
        this.ecoRewardsPercentage = 0; // between 0 and 1
        this.setupStep = 'account_intake';
        this.isBillingDisabled = false;
        this.isTestStore = false;
        this.isOnboardingComplete = false;
        this.invoiceFrequency = 'bi_weekly';
        this.contactEmail = '';
        this.siteUrl = '';
        this.logoUrl = '';
        this.hubSpotCompanyIdentifier = '';
        this.hubSpotContactIdentifier = '';
        this.hubSpotDealIdentifier = '';
        this.manufacturingCalculationType = 'defra_store';
        this.manufacturingStoreType = 'unspecified';
        this.manufacturingOffsetCapEnabled = false;
        this.pkgProtectionPricingMode = 'pct_aov';
        this.pkgProtectionPercentage = 0.025; // between 0 and 1
        this.pkgProtectionFlatRateAmount = 1.04;
        this.claimFormUrl = '';
        this.siMerchantUuid = '';
        /* --------------------------------- public --------------------------------- */
        this.systemVersion = 'v2';
        this.shopName = '';
        this.companyName = '';
        this.ecocartEnabled = false;
        this.platform = 'Shopify';
        this.payorMode = 'customer_paying';
        this.paymentServiceProvider = 'none';
        this.optOut = false; // turn off pre-check https://app.clickup.com/t/45007832/ECO-4364
        this.locale = 'en';
        this.currencyCode = 'USD';
        this.unitSystem = 'imperial';
        this.project = new MerchantProject();
        this.variantIds = [''];
        this.ecoCalculationApiKey = '';
        this.offsetMultiplier = 2;
        this.ecoIncentivesCartValueThreshold = 50;
        this.ecoIncentivesNumberOfTrees = 5;
        this.ecoIncentivesEnabled = false;
        this.pkgProtectionEnabled = false;
        this.pkgProtectionOrderCapEnabled = false;
        this.pkgProtectionOrderCapAmount = 1000;
        this.cashbackPercentage = 0;
        this.cashbackEnabled = false;
    }
}
exports.MerchantAdmin = MerchantAdmin;
